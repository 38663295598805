import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import Section from '@components/Section'
import Heading, { headingConstants } from '@components/Heading'
import ScrollIndicator from '@components/ScrollIndicator'
import Image from '@components/Image'
import Pill from '@components/Pill'
import LayoutHeroMobile from '@components/Layout/Layout.Hero.Mobile'
import Transitions from '@components/Transitions'

import media from '@styles/media'

// TODO: move this to contentfu!!!
const imageQuery = graphql`
  query ThingsHeroQuery {
    hero: file(name: { regex: "/drafted-device-mobile/" }) {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroLoading: file(name: { regex: "/drafted-device-loading/" }) {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroActive: file(name: { regex: "/drafted-device-active/" }) {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

function ThingsHero() {
  const [current, setCurrent] = useState(0)
  // Start the hero animation once the image has laoded
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <StaticQuery
      query={imageQuery}
      render={({ hero, heroLoading, heroActive }) => (
        <>
          <LayoutHeroMobile>
            <HeroSection hideOverflow>
              <ContentContainer>
                <div />
                <Transitions.CSS.FadeIn>
                  <TextContainer>
                    <Pill text="Things" />
                    <Heading.h2 styles="h1">
                      Whether with clients or on my own, I'm most of the time
                      busy crafting new things or improving older ones
                    </Heading.h2>
                    <MainText>
                      Here's a peek of things I've been recently giving love to.
                    </MainText>
                  </TextContainer>
                </Transitions.CSS.FadeIn>
                <ScrollIndicator />
              </ContentContainer>
              <HeroImage>
                <Image
                  onLoad={() => setImageLoaded(true)}
                  src={heroLoading.childImageSharp.fluid}
                />
                <div
                  style={{
                    opacity: imageLoaded ? 1 : 0,
                    transition: 'opacity 1s ease 0.5s',
                  }}
                >
                  <Image src={heroActive.childImageSharp.fluid} />
                </div>
              </HeroImage>
            </HeroSection>
          </LayoutHeroMobile>
          <HeroImageMobile>
            <Image src={hero.childImageSharp.fluid} />
          </HeroImageMobile>
        </>
      )}
    />
  )
}

export default ThingsHero

const HeroSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeroImage = styled.div`
  position: relative;
  width: 610px;
  top: -180px;
  right: -40px;
  animation: float 4.8s ease-in-out infinite 1.4s;

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-8px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  ${media.tablet`
       display: none;
     `};

  .gatsby-image-wrapper {
    position: absolute !important;
    top: -180px;
    left: 0;
    width: 100%;
  }
`

const HeroImageMobile = styled(HeroImage)`
  display: none;

  ${media.tablet`
    box-shadow: 0px -40px 40px rgba(8, 7, 11, 0.8);
    display: block;
    width: 100%;
    top: 0;
    right: 0;
    margin-bottom: 60px;
  `};

  .gatsby-image-wrapper {
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
  }
`

const ContentContainer = styled.div`
  height: calc(100vh - 140px);
  min-height: 600px;

  a {
    color: #fff;
    font-size: 22px;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.desktop_medium`
    min-height: 360px;
  `};

  @media screen and (max-height: 800px) {
    min-height: 360px;
  }

  ${media.phablet`
    height: calc(100vh - 90px);
    padding: 0;
  `};
`

const TextContainer = styled.div`
  max-width: 570px;

  ${media.phablet`
    position: relative;
    top: -50px;
  `};
`

const MainText = styled.p`
  font-size: ${headingConstants.h1.fontsize};
  font-weight: 400;
  color: ${(p) => p.theme.colors.grey};
  line-height: ${headingConstants.h1.lineheight};

  ${media.phablet`
    font-size: ${headingConstants.h1.fontsizeMobile};
    line-height: ${headingConstants.h1.lineheightMobile - 0.1};
  `};
`

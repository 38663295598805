import React from 'react'
import styled from 'styled-components'

const Pill = ({ text }: { text: string }) => (
  <Frame>
    <Heading>{text}</Heading>
    <PillOutline aria-hidden="true" />
  </Frame>
)

export default Pill

const Frame = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
`

const Heading = styled.h1`
  position: absolute;
  top: 10px;
  color: #fff;
  width: 100%;
  text-align: center;
`

const PillOutline = () => (
  <svg
    width="111"
    height="45"
    viewBox="0 0 111 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 29V4M6 29H107M6 29V32M6 29H0.5M6 4H61M6 4V1M6 4H4M107 29V23M107 29H110.5M107 29V35.5"
      stroke="url(#paint0_linear)"
      stroke-opacity="0.8"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="35.5"
        y1="43"
        x2="55.5"
        y2="-9.94637e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.4" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

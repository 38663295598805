import React from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'

import Image from '@components/Image'
import ButtonArrow from '@components/Button/Button.Arrow'
import ThingLinks from '@components/ThingLinks'
import StackLinks from '@components/StackLinks'
import media from '@styles/media'
import * as LogoLibrary from '@logos'

const ThingPreview = ({ thing, location, fill = 'unset' }) => {
  // console.log('This is the thing passed to ThingPreview')
  // console.log(thing)
  const key = thing.id + '_preview'

  // setting the content darkness (default is light)
  const dark = lightOrDark(thing.backgroundColor) === 'dark' ? true : false

  // setting the logo
  const logo = LogoLibrary[thing.logo]
  const logoFill = dark ? 'rgba(0, 0, 0, 1)' : 'rgba(255,255,255,1)'

  // We get the current path to pass it to our Thing page in order to come back on close
  const previousPath = location

  // TODO: <Logo dark={dark}>{LogoLibrary.Figma}</Logo>
  return (
    <Card key={key} background={thing.backgroundColor}>
      <Content>
        <Logo dark={dark}>{logo(logoFill)}</Logo>
        <StackLinksContainer>
          <StackLinks links={thing.tooling} dark={dark} />
          <StackLinks links={thing.technoStack} dark={dark} />
        </StackLinksContainer>
        <Excerpt dark={dark}>
          {thing.excerpt}
          {thing.readMore ? (
            <ButtonArrowPreview
              text="Read more"
              color={dark ? 'rgba(0, 0, 0, 1)' : 'rgba(255,255,255,1)'}
              dark={dark}
              onClick={() =>
                navigate(`/${thing.pathPrefix}/${thing.slug}`, {
                  state: { previousPath },
                })
              }
            />
          ) : null}
        </Excerpt>
        <HorizontalRule dark={dark} />
        <ThingLinksContainer>
          <ThingLinks links={thing.links} dark={dark} />
        </ThingLinksContainer>
      </Content>
      <ImageContainer>
        <Image src={thing.backgroundImage.wide.fluid} />
      </ImageContainer>
    </Card>
  )
}

export default ThingPreview

const lightOrDark = (color) => {
  // Tip from https://awik.io/determine-color-bright-dark-using-javascript/
  // Variables for red, green, blue values
  let r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'dark'
  } else {
    return 'light'
  }
}

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  padding: 80px 70px;
  background: ${(p) => p.background};
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;

  &:not(:last-child) {
    margin: 30px auto;
  }

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    top: 0;
    left: 0;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.16);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }

  ${media.tablet`
    display: none;
  `}
`

// ORIGINAL: max-width: 371px;
const Content = styled.div`
  position: relative;
  z-index: 1;
  max-width: 400px;

  ${media.phablet`
    text-align: center;
    margin: 0 auto;
  `}
`

const Logo = styled.div`
  margin-bottom: 25px;

  & svg {
    margin-right: 13px;
    fill: ${(p) => (p.dark ? '#08080B' : '#fff')};
    height: 4rem;
    width: auto;
  }
`

const Excerpt = styled.p`
  font-size: 1.5rem;
  color: ${(p) =>
    p.dark ? 'rgba(8, 8, 11, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  margin-bottom: 30px;

  ${media.phablet`
    margin-bottom: 25px;
  `}
`
const ButtonArrowPreview = styled(ButtonArrow)`
  ${'' /* margin-top: -20px;
  margin-bottom: 30px; */}

  & > div {
    font-size: 1.5rem;
  }
`

const HorizontalRule = styled.hr`
  width: 140px;
  height: 1px;
  border: none;
  margin-bottom: 30px;
  background: ${(p) =>
    p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.1)'};

  ${media.phablet`
       width: 100%;
       margin: 0 auto 25px;
       background: ${(p) =>
         p.dark ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255,255,255,0.1)'};
     `}
`

const ThingLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
`

const StackLinksContainer = styled(ThingLinksContainer)`
  margin-bottom: 12px;
  justify-content: right;
  flex-align: right;
`

// TODO: Get colors from theme : ${(p) => p.theme.colors.${colorName}}
// Link to a file of the Thing
const LinkToThing = styled.a`
  position: relative;
  font-weight: 800;
  color: ${(p) =>
    p.inactive ? p.theme.colors.grey : p.dark ? '#08080B' : '#fff'};

  &:nth-child(n + 2) {
    margin-left: 30px;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${(p) => p.theme.colors.blue};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  svg {
    margin-right: 13px;
    fill: ${(p) => (p.dark ? '#08080B' : '#fff')};
  }

  ${media.tablet`
       display: block;
       margin: 0 auto;
       color: ${(p) => (p.dark ? '#08080B' : '#fff')};

       &:nth-child(2) {
         margin: 15px auto 0;
       }

         svg {
           display: none;
         }
     `}
`

const ImageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 69%;
  top: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }

  ${media.tablet`
    display: none;
  `}
`

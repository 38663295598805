import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import media from '@styles/media'
import * as Icons from '@icons/ui'

function ThingLinks({ links, dark, fill = '#73737D' }) {
  if (!links) return null

  return (
    <>
      {links.map((link) => {
        if (!link) {
          return
        }
        // set up link icon
        const Icon = Icons[link.icon]
        // set main link attributes
        const enabled = !link.comingSoon
        const internal = link.target

        return (
          <ThingLink
            as={internal ? Link : null}
            // to={`/${parent.pathPrefix}/${parent.slug}`}
            to={internal ? link.url : null}
            key={enabled ? link.url : null}
            href={enabled ? link.url : null}
            target={enabled && !internal ? '_blank' : null}
            rel={enabled && !internal ? 'noopener' : null}
            style={enabled ? { cursor: 'pointer' } : { cursor: 'default' }}
            data-a11y={internal ? 'true' : 'false'}
            aria-label={enabled ? `Link to ${link.url}` : 'Coming soon'}
            dark={dark ? 1 : 0}
            inactive={enabled ? 0 : 1}
          >
            {Icon ? <Icon aria-hidden="true" fill="unset" /> : null}
            {link.text}
            <Hidden>Link to {link.text}</Hidden>
          </ThingLink>
        )
      })}
    </>
  )
}

export default ThingLinks

// TODO: Get colors from theme : ${(p) => p.theme.colors.${colorName}}
const ThingLink = styled.a`
  position: relative;
  font-weight: 800;
  color: ${(p) => (p.dark ? '#08080B' : '#fff')};
  opacity: ${(p) => (p.inactive ? '.4' : '1')};

  &:nth-child(n + 2) {
    margin-left: 30px;
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -10%;
    top: -42%;
    width: 120%;
    height: 200%;
    border: 2px solid ${(p) => p.theme.colors.blue};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  svg {
    margin-right: 13px;
    fill: ${(p) =>
      p.inactive ? p.theme.colors.grey : p.dark ? '#08080B' : '#fff'};
  }

  ${media.tablet`
       display: block;
       margin: 0 auto;
       color: ${(p) =>
         p.inactive ? p.theme.colors.grey : p.dark ? '#08080B' : '#fff'};

       &:nth-child(2) {
         margin: 15px auto 0;
       }

         svg {
           display: none;
         }
     `}
`

const Hidden = styled.span`
  width: 0px;
  height: 0px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  display: inline-block;
`

// TODO: make use of pageContext as in src/sections/articles/Articles.Pagination.tsx
// This is just a wrapper to map things to their preview cards
import React from 'react'

import ThingPreview from './Things.Preview'
import ThingPreviewMobile from './Things.Preview.Mobile'

import { IThingNode } from '@typings'

/**
 * This is based on Things.Grid and is replicating /stuffs.StuffsBody
 * page.section, aimed at replacing it and integrating CMS @contentful
 */
const ThingsList = ({
  things,
  location,
}: {
  things: IThingNode[]
  location: string
}) => {
  return (
    <>
      {things.map((thing) => (
        <ThingPreviewMobile
          key={thing.id + '_m'}
          thing={thing}
          location={location}
        />
      ))}
      {things.map((thing) => (
        <ThingPreview key={thing.id} thing={thing} location={location} />
      ))}
    </>
  )
}

export default ThingsList

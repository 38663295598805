import React from 'react'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import styled, { keyframes } from 'styled-components'

import Image from '@components/Image'
import media from '@styles/media'
import ButtonArrow from '@components/Button/Button.Arrow'
import * as LogoLibrary from '@logos'
import ThingLink from '@components/ThingLinks'
import StackLinks from '@components/StackLinks'

const ThingPreviewMobile = ({ thing, location, fill = 'unset' }) => {
  // console.log('This is the thing passed to ThingPreviewMobile')
  // console.log(thing)
  const key = thing.id + '_preview_m'

  // setting the logo
  const logo = LogoLibrary[thing.logo]
  // setting the content darkness (default is light)
  const dark = lightOrDark(thing.backgroundColor) === 'dark' ? true : false
  // We have only one instance of the ThingLink on mobile so we will use only the first one
  const links = [thing.links[0]]

  // Setting up the excerpt color according to backgroun color darkness
  // const excerptColor = colorLuminance(thing.backgroundColor, 10)
  const excerptColor = dark ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.6)'

  // We get the current path to pass it to our Thing page in order to come back on close
  const previousPath = location

  // TODO: implement thing.name as a short version of thing.title
  return (
    <>
      <PreviewCard key={key} background={thing.backgroundColor}>
        <Heading dark={dark}>{logo ? logo(fill) : thing.title}</Heading>
        <Image src={thing.backgroundImage.square.fluid} />
        <Border dark={dark} />
        <Excerpt
          background={colorLuminance(thing.backgroundColor, -0.15)}
          color={excerptColor}
          onClick={
            thing.readMore
              ? () =>
                  navigate(`/${thing.pathPrefix}/${thing.slug}`, {
                    state: { previousPath },
                  })
              : null
          }
        >
          {thing.excerpt}
          {thing.readMore ? (
            <ButtonArrowPreview
              text="Read more"
              // color={dark ? 'rgba(0, 0, 0, 1)' : 'rgba(255,255,255,1)'}
              color={excerptColor}
              dark={dark}
              onClick={() =>
                navigate(`/${thing.pathPrefix}/${thing.slug}`, {
                  state: { previousPath },
                })
              }
            />
          ) : null}
        </Excerpt>
        <StackLinksContainer
          background={colorLuminance(thing.backgroundColor, -0.15)}
        >
          <StackLinks links={thing.tooling} dark={dark} />
          <StackLinks links={thing.technoStack} dark={dark} />
        </StackLinksContainer>
        <Anchor
          as="div"
          background={colorLuminance(thing.backgroundColor, -0.15)}
        >
          <ThingLink links={links} dark={dark} />
        </Anchor>
      </PreviewCard>
    </>
  )
}

export default ThingPreviewMobile

const lightOrDark = (color) => {
  // Tip from https://awik.io/determine-color-bright-dark-using-javascript/
  // Variables for red, green, blue values
  let r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'dark'
  } else {
    return 'light'
  }
}

const colorLuminance = (hex, lum) => {
  // return lighter (+lum) or darker (-lum) color as a hex string
  // pass original hex string and luminosity factor, e.g. -0.1 = 10% darker
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

const animateButtonLine = keyframes`
  0% {
      width: 0;
  }
  50% {
      width: 70%;
  }
  100% {
      width: 70%;
      left: 100%;
  }
`

const PreviewCard = styled.div`
  border-radius: 5px;
  overflow: hidden;
  background: ${(p) => p.background};
  margin-bottom: 25px;

  ${media.desktop_up`
    display: none;
  `}
`
const Heading = styled.h3`
  color: ${(p) => (p.dark ? '#fafafa' : '#000')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 15px;
  font-size: 2rem;
  color: #fff;
  font-family: ${(p) => p.theme.fontfamily.serif};

  svg {
    margin-right: 13px;
    fill: ${(p) => (p.dark ? '#08080B' : '#fff')};
    height: 4rem;
    width: auto;
    max-width: 100%;
  }
`
const Border = styled.div`
  background: ${(p) =>
    p.dark ? 'rgba(255,255,255,0.06)' : 'rgba(0,0,0,0.06)'};

  position: relative;
  top: 1px;
  height: 1px;
`
const Anchor = styled.div`
  background: ${(p) => p.background};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 1.6rem;
  font-weight: 800;
`

const StackLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-align: center;
  justify-content: center;
  background: ${(p) => p.background};
`

const ButtonArrowPreview = styled(ButtonArrow)`
height: auto;
max-width: none;
margin-top: .5em;
line-height: 1.6;

& > div {
  height: auto;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  ${
    '' /* color: ${(p) => (p.dark ? 'rgba(0,0,0,0.6)' : 'rgba(255,255,255,0.6)')}; */
  }
  color: ${(p) => p.color};
  opacity: .4;

  &:hover {
    opacity: 1;
  }

  & svg {
    margin-top: -1.4rem;
    margin-bottom: 0.7rem;
    top: auto;
    align-self: flex-end;
  }

  &:hover svg {
    transform: translateX(4rem);
  }

  &::after {
    top: auto;
    bottom: 10px;
    background: ${(p) => p.color};
    opacity: 0;
    z-index: 100;
  }

  &:hover::after {
    ${media.tablet`
      opacity: 1;
      animation: ${animateButtonLine} 1s cubic-bezier(0.77, 0, 0.175, 1) forwards;
      `}
    }
  }
  `
const Excerpt = styled(Anchor)`
  height: auto;
  min-height: 60px;
  padding: 20px 30px;
  flex-flow: column;
  align-items: flex-start;
  color: ${(p) => p.color};
  font-size: 1.5rem;
  font-weight: 300;
  user-select: none;

  &:hover ${ButtonArrowPreview} > div {
    opacity: 1;
  }
  &:hover ${ButtonArrowPreview} svg {
    transform: translateX(4rem);
  }
`

import React from 'react' // TODO: make use of useState to handle animation as in src/pages/stuffs/index.tsx
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Footer from '@components/Navigation/Navigation.Footer'
import Paginator from '@components/Navigation/Navigation.Paginator'
import Section from '@components/Section'
import SEO from '@components/SEO'
import Layout from '@components/Layout'

import ThingsHero from '../../sections/things/Things.Hero'
import ThingsGrid from '../../sections/things/Things.Grid'
import ThingsList from '../../sections/things/Things.List'
import ThingsFeatured from '../../sections/things/Things.Featured'
import ThingsPagination from '../../sections/articles/Articles.Pagination'

/**
 * thi$ web$ite/thing$
 *
 * This template is used to present / list the things pulled from Contentful.
 * This is not located in the /pages folder because we’re creating the pages
 * during Gatsby's createPages lifecycle
 */

function ThingsPage({ data, location, pageContext }) {
  const { seo } = data.allContentfulPage.edges[0].node
  const things = pageContext.group
  const featured = pageContext.additionalContext.featuredThing[0]

  const navConfig = {
    offset: true,
    fixed: true,
    theme: 'light',
  }

  const footerConfig = {
    visible: true,
  }

  return (
    <Layout nav={navConfig} footer={footerConfig}>
      <>
        <SEO
          title={seo.title}
          description={seo.description}
          image={seo.image.file.url}
          pathname={location.pathname}
        />
        <ThingsHero />
        <ThingsBody>
          <Section narrow>
            {featured ? <ThingsFeatured thing={featured} /> : null}
            <ThingsList things={things} location={location.pathname} />
            <ThingsPagination
              mode={navConfig.theme}
              pageContext={pageContext}
            />
          </Section>
        </ThingsBody>
      </>
    </Layout>
  )
}

export default ThingsPage

export const pageQuery = graphql`
  query ThingsPageQuery {
    allContentfulPage(filter: { pageName: { eq: "Things" } }) {
      edges {
        node {
          seo {
            title
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

const WhiteBackground = styled.div`
  position: relative;
  background: #fafafa;
`

const ThingsBody = styled.div`
  position: relative;
  z-index: 1;
`

import React from 'react'
import styled from 'styled-components'

import Paginator from '@components/Navigation/Navigation.Paginator'

import media from '@styles/media'

function ArticlesPagination({ pageContext, mode }) {
  if (pageContext.pageCount <= 1) return null

  return (
    <HorizontalRule mode={mode}>
      <Paginator {...pageContext} />
    </HorizontalRule>
  )
}

export default ArticlesPagination

const HorizontalRule = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.25);

  ${media.tablet`
    margin-bottom: 60px;
  `}

  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 12px;
    width: ${100 - (230 / 1140) * 100}%;
    height: 1px;
    background: ${(p) =>
      p.mode == 'light' ? `rgba(255, 255, 255, 0.05)` : `rgba(0, 0, 0, 0.25)`};
  }

  ${media.tablet`
    &::before {
      width: calc(100vw - 180px);
    }
  `}

  & > nav {
    position: relative;
    background: transparent;
    z-index: 1;
    top: -17px;
    width: ${(230 / 1140) * 100}%;

    ${media.tablet`
      width: auto;
      padding-right: 20px;
    `}
  }
`
